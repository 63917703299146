import React from 'react'
import './App.css';

const Resume = (props) => {


    return (<div id="ResumeDiv">
        
    </div>)
}


export default Resume;
